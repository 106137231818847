import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Ticket } from '@ttstr/api/products';
import { Container, ProductDetailHeader, ProductInfoBar, VariantChooser } from '@ttstr/components';
import MerchantInfo from '@ttstr/components/ProductDetail/MerchantInfo';

interface OwnProps {
  readonly product: Ticket;
}

type Props = Readonly<OwnProps>;

const TicketDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();

  return (
    <article className="product-detail" itemScope itemType="http://schema.org/Event">
      <Helmet>
        <title>{product.title}</title>
        {product.description && <meta name="description" content={product.description} />}
      </Helmet>

      <ProductDetailHeader
        className="product-header"
        background={product.image.productthumb.url}
        image={product.image.productthumb.url}
      />

      <div className="product-content">
        <ProductInfoBar product={product} />

        <Container className="detail-page">
          <header className="text-center">
            {product.supertitle && <span className="text-muted">{product.supertitle}</span>}
            <h1 className="display-5 my-3">
              <span itemProp="name">{product.title}</span>
              <small>{product.subtitle}</small>
            </h1>
          </header>
          <Row>
            <Col xl className="order-xl-last">
              <VariantChooser product={product} />
            </Col>
            {product.description && (
              <Col xl className="order-xl-first">
                <h2 className="product-title">{t(`PRODUCT.INFORMATION`)}</h2>
                <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
              </Col>
            )}
            {product.merchant_id && <MerchantInfo merchantId={product.merchant_id} />}
          </Row>
        </Container>
      </div>
    </article>
  );
};

export default React.memo(TicketDetail);
